
import { Component, Prop } from "vue-property-decorator";
import ListMixin from "@/mixins/Crud/ListMixin";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import { Machine } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { MachineStoreMixin } from "@/mixins/Stores/MachineStoreMixin.ts";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import Consts from "../../../helper/Consts";

@Component({
  computed: {
    Consts() {
      return Consts;
    },
  },
  components: { DefaultTableLayout },
  mixins: [ListMixin],
})
export default class MachineList extends mixins(MachineStoreMixin, ListMixin) {
  @Prop({ required: false, default: "Dashboard" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "name" })
  public descriptionField!: string;

  protected get resource(): string {
    return Machine.resource;
  }

  protected loadList(options: ICrudOptions): any {
    return this.findAllMachineItemsAction(options);
  }

  protected init() {
    this.$echo.channel("Machine").listen(".MachineDeleted", (e: any) => {
      this.onReloadTable();
    });
  }
}
